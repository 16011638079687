import { useEffect, useState } from "react";
import KeyListItem from "../KeyListItem/KeyListItem";
import List from "../List/List";

export default function KeyList(props) {
    const { keys } = props

    const [sortBy, setSortBy] = useState('id')
    const [sortedList, setSortedList] = useState([])

    useEffect(() => {
        const copiedKeys = [...keys]
        switch (sortBy) {
            case 'id':
                setSortedList(copiedKeys.sort((a,b) => a.id-b.id))
                return
            case 'from_limited':
                setSortedList(
                    copiedKeys.sort((a,b) => {
                        if (a.limit_bytes === b.limit_bytes) {
                            return 0
                        }

                        if (a.limit_bytes === 0) {
                            return -1
                        }
                        return 1
                    })
                )
                return
            case 'from_not_limited':
                setSortedList(
                    copiedKeys.sort((a,b) => {
                        if (a.limit_bytes === b.limit_bytes) {
                            return 0
                        }

                        if (a.limit_bytes === 0) {
                            return 1
                        }
                        return -1
                    })
                )
                return
            case 'from_trial':
                setSortedList(
                    copiedKeys.sort((a,b) => {
                        if (a.is_trial === b.is_trial) {
                            return 0
                        }

                        if (a.is_trial) {
                            return -1
                        }
                        return 1
                    })
                )
                return
            case 'from_not_trial':
                setSortedList(
                    copiedKeys.sort((a,b) => {
                        if (a.is_trial === b.is_trial) {
                            return 0
                        }

                        if (a.is_trial) {
                            return 1
                        }
                        return -1
                    })
                )
                return
            default:
                return
        }
    }, [sortBy, keys])

  return (
    <div>
      <List
        items={sortedList}
        ItemComponent={KeyListItem}
        title='Ключи'
        sortList={ [
          {
            name: 'ID',
            value: 'id'
          },
          {
            name: 'Сначала ограниченные',
            value: 'from_limited'
          },
          {
            name: 'Сначала не ограниченные',
            value: 'from_not_limited'
          },
          {
            name: 'Сначала пробные',
            value: 'from_trial'
          },
          {
            name: 'Сначала платные',
            value: 'from_not_trial'
          }
        ] }
        setSortBy={setSortBy}
        sortBy={sortBy}
      />
    </div>
  );
}

import { useEffect, useState } from "react";
import API from "./API/API";
import AccountList from "./components/AccountList/AccountList";
import KeyList from "./components/KeyList/KeyList";
import PaymentList from "./components/PaymentList/PaymentList";
import Statistics from "./components/Statistics/Statistics";

function App() {
  const [accounts, setAccounts] = useState([])
  const [payments, setPayments] = useState([])
  const [keys, setKeys] = useState([])

  useEffect(() => {
      API.getAccounts().then(setAccounts).catch(e => console.error(e))
      API.getPayments().then(setPayments).catch(e => console.error(e))
      API.getKeys().then(setKeys).catch(e => console.error(e))
    }, [])

  return (
    <div className="App">
      <Statistics
        keys={keys}
        payments={payments}
        accounts={accounts}
      />
      <KeyList keys={keys} />
      <PaymentList payments={payments} />
      <AccountList accounts={accounts} />
    </div>
  );
}

export default App;

import styleClasses from './Statistics.module.css'

export default function Statistics(props) {
  const { accounts, payments, keys } = props

  const serverNames = keys.reduce((p, c) => {
    if (!p.includes(c.name)) {
      p.push(c.name)
    }
    return p
  }, [])

  const now = new Date()
  const dateFrom = now.setDate(now.getDate() - 30)
  const dayAgo = new Date().setDate(now.getDate() - 1)

  function sum(obj) {
    return obj.reduce((p, c) => p + c.value, 0)
  }

  return (
    <div className={styleClasses.container}>
      <div className={styleClasses.title}>
        Статистика
      </div>
      <div>
        Активных триальщиков: {`${keys.filter(k => k.is_trial && k.limit_bytes !== 0).length} / ${keys.filter(k => k.is_trial).length}`}
      </div>
      <div>
        Статистика по серверам:
        {
          serverNames.map(s => (
            <div> {
              `${s} - ${
                keys.filter(k => k.name === s && k.limit_bytes !== 0).length
              } / ${
                keys.filter(k => k.name === s).length
              }`
            } </div>
          ))
        }
      </div>
      <div>
        Новых пользователей за месяц: {`${accounts.filter(a => new Date(a.created_at) > dateFrom).length} / ${accounts.length}`}
      </div>
      <div>
        Новых пользователей за день: {`${accounts.filter(a => new Date(a.created_at) > dayAgo).length}`}
      </div>
      <div>
        Выручка за месяц: {`${sum(payments.filter(a => new Date(a.created_at) > dateFrom)) / 100} / ${sum(payments) / 100}`}
      </div>
    </div>
  );
}

import axios from 'axios'

export default class API {
    static API_URL = process.env.NODE_ENV === 'development' ? 'http://localhost:5000/api' : '/api'

    static async getAccounts() {
        const endpoint = '/accounts'
        const resp = await axios.get(`${this.API_URL}${endpoint}`)
        return resp.data.accounts
    }

    static async getKeys() {
        const endpoint = '/keys'
        const resp = await axios.get(`${this.API_URL}${endpoint}`)
        return resp.data.keys
    }

    static async getPayments() {
        const endpoint = '/payments'
        const resp = await axios.get(`${this.API_URL}${endpoint}`)
        return resp.data.payments
    }
}
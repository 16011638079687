import { useEffect, useState } from "react";
import List from "../List/List";
import AccountListItem from "../AccountListItem/AccountListItem";

export default function AccountList(props) {
    const { accounts } = props

    const [sortBy, setSortBy] = useState('created_at')
    const [sortedList, setSortedList] = useState([])

    useEffect(() => {
        const copiedList = [...accounts]
        switch (sortBy) {
            case 'id':
                setSortedList(copiedList.sort((a,b) => a.id-b.id))
                return
            case 'created_at':
                setSortedList(
                    copiedList.sort((a,b) => {
                        const d1 = new Date(a.created_at)
                        const d2 = new Date(b.created_at)
                        return d2 - d1
                    })
                )
                return
            default:
                return
        }
    }, [accounts, sortBy])

  return (
    <div>
      <List
        items={sortedList}
        ItemComponent={AccountListItem}
        title='Пользователи'
        sortList={ [
          {
            name: 'ID',
            value: 'id'
          },
          {
            name: 'Сначала новые',
            value: 'created_at'
          }
        ] }
        sortBy={sortBy}
        setSortBy={setSortBy}
      />
    </div>
  );
}

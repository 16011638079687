import { formatDateTime } from '../../utils/date';
import styleClasses from './KeyListItem.module.css'

export default function KeyListItem(props) {
    const { item } = props

    return (
        <div className={styleClasses.itemRow}>
            <div>
                ID: { item.id }
            </div>
            <div>
                Юзернейм: { item.username || 'Отсутствует' }
            </div>
            <div>
                ID аккаунта: { item.external_account_id }
            </div>
            {
                item.limit_bytes === 0
                ? <div className={styleClasses.red}>Ограничен</div>
                : null
            }
            {
                item.is_trial
                ? <div className={styleClasses.green}>Пробная</div>
                : null
            }
            <div>
                Сервер: { item.name }
            </div>
            <div>
                Действует до: { formatDateTime(item.expired_at) }
            </div>
            <div>
                Создан: { formatDateTime(item.created_at) }
            </div>
        </div>
    );
}

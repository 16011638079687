import { formatDateTime } from '../../utils/date';
import styleClasses from './PaymentListItem.module.css'

export default function PaymentListItem(props) {
    const { item } = props

    return (
        <div className={styleClasses.itemRow}>
            <div>
                ID: { item.id }
            </div>
            <div>
                Сумма: { item.value / 100 }₽
            </div>
            <div>
                Юзернейм: { item.username || 'Отсутствует' }
            </div>
            <div>
                ID аккаунта: { item.external_account_id }
            </div>
            <div>
                Сделан: { formatDateTime(item.created_at) }
            </div>
        </div>
    );
}

import { useState } from 'react';
import styleClasses from './List.module.css'
import ListIcon from './ListIcon';

export default function List(props) {
    const { items, ItemComponent, title, sortList, setSortBy, sortBy } = props
    const [ isHidden, setIsHidden ] = useState(true)

    const changeIsHidden = () => {
        setIsHidden(!isHidden)
    }

    return (
        <div className={styleClasses.container}>
            <div
                className={styleClasses.title}
                onClick={changeIsHidden}
            >
                {title}
                <ListIcon isHidden={isHidden} />
            </div>
            {
                isHidden
                ? null
                : <div>
                    <div className={styleClasses.sortBy}>
                        <label>Сортировать по:</label>
                        <select onChange={(e) => {setSortBy(e.target.value)}} defaultValue={sortBy}>
                            {
                                sortList.map(sortItem => (
                                    <option value={sortItem.value}>{sortItem.name}</option>
                                ))
                            }
                        </select>
                    </div>
                    <div className={styleClasses.list}>
                        {
                            items.map(item => <ItemComponent key={item.id} item={item} />)
                        }
                    </div>
                </div>
            }
        </div>
    );
}

import { formatDateTime } from '../../utils/date';
import styleClasses from './AccountListItem.module.css'

export default function AccountListItem(props) {
    const { item } = props

    return (
        <div className={styleClasses.itemRow}>
            <div>
                ID: { item.id }
            </div>
            <div>
                Юзернейм: { item.username || 'Отсутствует' }
            </div>
            <div>
                Telegram ID: { item.external_account_id }
            </div>
            <div>
                Создан: { formatDateTime(item.created_at) }
            </div>
        </div>
    );
}

import styleClasses from './ListIcon.module.css'

export default function ListIcon(props) {
    const { isHidden } = props

    const classes = [styleClasses.icon]
    if (isHidden) {
        classes.push(styleClasses.checked)
    }

    return (
        <i className={classes.join(' ')} checked={isHidden}>
            <svg viewBox="0 0 20 20">
                <path d="M0,0 20,10 0,20" />
            </svg>
        </i>
    );
}
